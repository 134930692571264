.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* canvas#deckgl-overlay {
  mix-blend-mode: color-burn;
} */

div.recharts-legend-wrapper {
  transform: translateY(20px);
}
/* div.recharts-responsive-container.radial {
  margin-bottom:-75%;
} */
div.chartGrid,div.BarGrid  {
  /* padding-bottom: 25%; */
  /* box-sizing: content-box; */
  /* min-height: 800px; */
}

div.BarGrid {
  min-height:400px;
}

button.infoButton {
  margin:0 10px 0 0;
  width:15px;
  height:15px;
  padding:5px;
  text-transform:lowercase;
  font-size:50%;
  min-width: 0;
  transform: translate(-10px,13px);
  background:#84d4f1;
  border-radius:50%;
  color:white;
}
button.infoButton.active {
  background:#96f184;
}
button.infoButton span {
  padding:0;
  margin:0;
}
p.scenarioText {
  font-weight: 400;
  font-size:75%;
  width: 20vw;
  min-width: 400px;
}
a.peopleLink {
  color:black;
}
a.peopleLink:hover {
  color:gray;
}

div.deck-tooltip {
  background-color:white !important;
  color:black !important;
  text-align:left;
  text-transform: capitalize;
  box-shadow:2px 2px 5px rgb(0,0,0,0.4);
}
button.closeButton {
  position: absolute;
  top:0;
  right:0;
  font-size:20px;
}
div.drawer-transparent div.MuiPaper-root, div.layer-select div.MuiPaper-root  {
  background:rgba(255,255,255,0.85);
}
table.stationsDataTable, 
table.stationsDataTable th,
table.stationsDataTable td {
  font-size:12px;
  border-collapse: collapse;
  text-align: left;
  vertical-align: bottom;
  border:1px solid rgba(0,0,0,0.5);
  font-family:'Roboto Condensed', 'Roboto', sans-serif;
}
div.hidden {
  display:none;
}

table.stationsDataTable th,
table.stationsDataTable td {
  padding:3px 2px;
}

button#closeLegend, button#openLegend {
  float:right;
  color:white;
  font-size: 12px;
}
button#openLegend {
  font-size:12px;
  color:black;
}

button.closeLegend {
  float:right;
  margin-left:-100%;
}

button#openLegend span div.MuiPaper-root {
  padding: 2px 6px;
}

button#openLegend 
button#closeLegend span{
  text-shadow: 0px 0px 5px rgba(0,0,0,0.5);
}
table.MetricsTable {
  width:100%;
  text-align: left;
  border-collapse: collapse;
  transform:translateY(-200%);
}
div.scenariosDescription {
  transform:translateY(-100%);
}
@media (max-width:1024px) {
  table.MetricsTable {
    transform:translateY(-150%);
  }
}
table.MetricsTable tr:nth-last-of-type(even) {
  background:rgba(0,0,0,0.1);
}
table.MetricsTable tr td,
table.MetricsTable tr th {
  padding:2px 6px;
}
div.MuiTabs-root.drawerTabs {
  background:rgb(225,225,225);
  color:black;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
